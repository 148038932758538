import moment from 'moment';
import formats from '@/config/formats';
export const nowUnix = () => Math.round(Date.now() / 1000);
export default {
  // Convert a UNIX timestamp to app's
  // User Date Display format
  toNumericDateDisplay(unixtimestamp) {
    return unixtimestamp ? moment.unix(unixtimestamp).format(formats.numericDateDisplay) : moment().format(formats.numericDateDisplay);
  },

  toFullNumericDateDisplay(unixtimestamp) {
    return unixtimestamp ? moment.unix(unixtimestamp).format(formats.fullNumericDateDisplay) : moment().format(formats.numericDateDisplay);
  },

  toDateString(unixtimestamp) {
    return unixtimestamp ? moment.unix(unixtimestamp).format(formats.dateString) : moment().format(formats.dateString);
  },

  toFullTextDate(unixtimestamp) {
    return unixtimestamp ? moment.unix(unixtimestamp).format(formats.fullTextDate) : moment().format(formats.fullTextDate);
  },

  // UNIX to a time from date, e.g. 2 days ago
  toTimeFromDate(unixtimestamp) {
    return unixtimestamp ? moment.unix(unixtimestamp).fromNow() : moment().fromNow();
  },

  // UNIX to an ISO date string
  toIsoDate(unixtimestamp) {
    const ts = typeof unixtimestamp === 'number' ? unixtimestamp * 1000 : Date.now();
    const date = new Date(ts);
    return date.toISOString();
  },

  nowUnix
};